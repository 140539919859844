<template>
    <section class="content">
        <div class="content__inner content__inner--sm">
            <header class="content__title">
                <h1>Users</h1>
                <small>All users</small>
                <div class="actions">
                    <button class="btn btn-primary" @click="add">
                        <i class="zmdi zmdi-plus"></i>
                        Add User
                    </button>
                </div>
            </header>
            <router-view :key="$route.fullPath" />
        </div>
    </section>
</template>


<script>
export default {
    data(){
        return {
            users: [],
            isProcessing: false,
        }
    },
    created(){
        this.load()
    },
    methods: {
        async load(){
            this.isProcessing = true
            const logs = []
            const snapshot = await this.$db.collection("users").orderBy("createdAt", "desc").get()
            snapshot.forEach(doc => {
                const data = doc.data()
                if (data.createdAt) {
                    data.dob = data.dob && data.dob != null ? this.$moment(data.dob.toDate()).format('DD-MM-YYYY') : "NIL"
                    data.createdAt = this.$moment(data.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss a")
                }
                logs.push({ id: doc.id, ...data })
            })
            this.users = logs
            this.isProcessing = false
        },
        view(id){
            this.$router.push("/users/n/" + id)
        },
        edit(id){
            this.$router.push("/users/n/" + id + "/edit")
        },
        remove(id, i){
            console.log(id, i);
        },
        add(){
            this.$router.push("/users/add")
        },
    },
    components: {
        // "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>


<style scoped>
.thumbnail {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}
</style>